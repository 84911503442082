nav {
  background-color: rgb(255, 255, 255);
  color: rgb(64, 80, 115);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding-inline: 1rem;
  padding-block: .5rem;
}

.Navbar-title {
  font-size: 2rem;
  font-weight: 700;
  position: relative;
}

.Navbar-title::after {
  content: '👩🏽‍💼🧑🏻‍💼👨🏾‍💼';
  position: absolute;
  top: 0;
  right: -6rem;
  font-size: 2rem;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.Navbar-title:hover::after {
  opacity: 1;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

nav a, nav li a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
  transition: background-color 0.33s ease;
  position: relative;
}

nav li a.active {
  font-weight: bold;
}

nav li a:hover {
  background-color: rgb(222, 222, 222);
}