@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.JobCard {
  margin-inline: 1rem;
  margin-block: 1rem;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid black; */
  padding: 20px;
  border-radius: 10px;
  animation: slideIn 0.5s ease;
}

.JobCard-title {
  text-align: start;
  margin-top: 0;
  margin-bottom: .5rem;
}

.JobCard p {
  text-align: start;
  margin: 0;
}
