@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.CompanyCard {
  margin-inline: 1rem;
  margin-block: 1rem;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid black; */
  padding: 20px;
  border-radius: 10px;
  animation: slideIn 0.5s ease;
}

.CompanyCard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
}

.CompanyCard-logo {
  height: 30px;
}

.CompanyCard-name {
  margin-top: 0;
  margin-bottom: .5rem;
}

.CompanyCard a {
  text-decoration: none;
  color: black;
}

.CompanyCard p {
  text-align: start;
  margin: 0;
}