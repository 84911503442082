.CompanyDetail-header {
  color: white;
  padding: 1rem;
}

.CompanyDetail-header p {
  font-size: 1rem;
}

@keyframes rotate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.CompanyDetail-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CompanyDetail-header {
  background-color: rgba(0, 0, 0, 0.5);
}

#html-spinner {
  margin-top: 2rem;
  width:40px;
  height:40px;
  border:4px solid #fcd779;
  border-top:4px solid white;
  border-radius:50%;
}

#html-spinner {
  transition-property: transform;
  animation-name: rotate;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


/* https://coder-coder.com/background-image-opacity/ */
.CompanyDetail::before {
  content: "";
  height: 100%;
  background-image: url("https://static.vecteezy.com/system/resources/previews/016/474/827/original/business-and-work-seamless-pattern-png.png");
  background-size: 500px;
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .35;
  z-index: -1;
}

.SignUpForm {
  animation: slideIn 0.5s ease;
}